import "@fontsource/lato";
import { createTheme } from "@mui/material/styles";

export const colors = {
  lightGrey: "#ddd",
  success: "green",
  danger: "#BA1A1A",
  warning: "#F79035",
  gray: "#acacac",
  primary: "#f7b035",
  primaryFont: "#FFE6B9",
  primaryEmphasis: "#FFC969",
  darkFont: "#1B1202",
  dark: "#F79035",
  neutral: "#F8EFE7",
  info: "#0288d1",

  secondary: "#FFC969",
  secondaryLight: "#6E5C40",
  secondaryDark: "#7E5700",
};

export const sizes = {
  x1: "4px",
  x2: "8px",
  x3: "12px",
  x4: "16px",
  x5: "20px",
  x6: "24px",
  x7: "28px",
};

export const screens = {
  mobile: "480px",
  tablet: "768px",
  screenSmall: "1023px",
  screenLarge: "1200px",
};

export const fontSizes = { small: sizes.x2, large: sizes.x5 };

export const theme = createTheme({
  palette: {
    primary: {
      light: "#FFDEAD",
      main: "#FBBB4A",
      dark: "#7E5700",
      contrastText: "#432C00",
    },
    secondary: {
      light: "#F8DFBB",
      main: "#DBC3A1",
      dark: "#6E5C40",
      contrastText: "#FFFFFF",
    },
    error: {
      light: "#FFDAD6",
      main: "#BA1A1A",
      dark: "#FFB4AB",
      contrastText: "#93000A",
    },
    background: {
      paper: "#FFFBFF",
    },
  },
  typography: {
    allVariants: {
      fontFamily: "'Lato', sans-serif",
    },
    h1: {
      fontSize: "2.66rem",
      lineHeight: 1.2,
      fontWeight: 700,
      letterSpacing: 0.1,
      "@media (min-width:480px)": {
        fontSize: "3rem",
      },
    },
    h2: {
      fontSize: "2rem",
      lineHeight: 1.5,
      fontWeight: 600,
      letterSpacing: 0.1,
    },

    body1: {
      fontSize: "1.3rem",
      lineHeight: 1.2,
      letterSpacing: 1.5,
      "@media (min-width:480px)": {
        fontSize: "1.33rem",
        lineHeight: 1.5,
      },
    },
    body2: {
      fontSize: "0.9rem",
      lineHeight: 1.2,
      letterSpacing: 0.4,
      opacity: 0.7,

      "@media (min-width:480px)": {
        fontSize: "1.2rem",
        lineHeight: 1.5,
        letterSpacing: 0.5,
      },
    },
    subtitle1: {
      fontSize: "1.2rem",
      lineHeight: 1.8,
      letterSpacing: 0.1,
      fontWeight: 700,
      "@media (min-width:768px)": {
        fontSize: "1.83rem",
        lineHeight: 1.2,
        letterSpacing: 0.5,
      },
    },
    error: {
      fontSize: "1rem",
      lineHeight: 1.2,
      letterSpacing: 1.5,
      color: "red",
      "@media (min-width:480px)": {
        fontSize: "1.33rem",
        lineHeight: 1.5,
      },
    },
    success: {
      fontSize: "1rem",
      lineHeight: 1.2,
      letterSpacing: 1.5,
      color: "green",
      "@media (min-width:480px)": {
        fontSize: "1.33rem",
        lineHeight: 1.5,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 481,
      md: 768,
      lg: 1024,
      xl: 1200,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === "contained" &&
            ownerState.color === "primary" && {
              backgroundColor: "#7E5700",
              color: "#Ffffff",
              borderRadius: "16px",
            }),
        }),
      },
    },
  },
});
