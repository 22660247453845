import { AxiosError, isAxiosError } from "axios";
import { InferType, object, string } from "yup";

const APP_EXCEPTIONS = {
  InsufficientFunds: "App\\Exceptions\\InsufficientFundsException",
  DeletingAdminUser: "App\\Exceptions\\DeletingAdminUserException",
  DeletingOwnUser: "App\\Exceptions\\DeletingOwnUserException",
  DeletingMainCompany: "App\\Exceptions\\DeletingMainCompanyException",
  CustomerBalance: "App\\Exceptions\\CustomerPortfolioBalanceException",
};

export type ErrorKeys = keyof typeof APP_EXCEPTIONS;

export const createApiExceptionSchema = (type: ErrorKeys) => {
  return object({}).shape({
    exception: string().oneOf([APP_EXCEPTIONS[type]]).required(),
  });
};

type ErrorType = InferType<ReturnType<typeof createApiExceptionSchema>>;

export function matchAxiosApiException(
  type: ErrorKeys,
  data: unknown
): data is AxiosError<ErrorType> {
  const schema = createApiExceptionSchema(type);
  return isAxiosError(data) && schema.isValidSync(data.response?.data);
}

export function matchValidationException(
  field: string,
  data: unknown
): data is AxiosError {
  return (
    isAxiosError(data) &&
    data.response?.status === 422 &&
    data.response.data[field]
  );
}
