import { countQuery } from "@castiero/modules/js_query/count";
import { DataStoreActionType } from "@castiero/modules/redux/createDataSlice";
import { Identifiable } from "@castiero/modules/types/Identificable";
import { JsQuery } from "@castiero/modules/types/JsQuery";
import { DropdownOption } from "@castiero/modules/types/store";
import api from "@castiero/web/api";
import events, { EventKeys } from "@castiero/web/common/events";
import {
  Pagination,
  defaultPagination,
} from "@castiero/web/components/MUI/table/EnhancedTable";
import { v4 } from "uuid";
import { ApiServiceDeps } from "./types";

type FullRestGetItemServiceParams = {
  w?: Array<string>;
};

export type FullServiceQueryType = Record<
  string,
  string | number | { from: string; to: string }
>;

type FullRestLoadServiceParams = {
  q?: FullServiceQueryType;
  w?: Array<string>;
  wc?: Array<string>;
  s?: Array<string>;
  tags?: Array<string>;
  all?: boolean;
  noPaginate?: boolean;
  orderBy?: string | number | symbol;
  orderDir?: "asc" | "desc";
};

type PaginationResult<T> = {
  current_page: number;
  data: Array<T>;
  last_page: number;
  per_page: number;
  from: number;
  to: number;
  total: number;
};

export function fullRestServiceProvider<T extends Identifiable>(
  deps: ApiServiceDeps,
  uri: string,
  actions: DataStoreActionType<T>,
  eventKeys?: {
    onCreate: EventKeys;
    onUpdate: EventKeys;
    onDelete: EventKeys;
  }
) {
  return {
    // Load data add page and size attributes
    /** @deprecated please use loadJsQuery instead */
    async loadData(
      params?: FullRestLoadServiceParams,
      ppg: Pagination = defaultPagination
    ) {
      const lastQueryID = v4();
      actions.setLoading(lastQueryID);
      // Laravel pagination is index based 1 and frontend is 0
      const {
        data: { data, ...pagination },
      } = await deps.axios.get<PaginationResult<T>>(`${uri}`, {
        params: { ...params, perPage: ppg.perPage, page: ppg.page + 1 },
      });
      actions.setLoaded({
        data,
        lastQueryID,
        pagination: {
          page: pagination.current_page - 1,
          perPage: pagination.per_page,
          total: pagination.total,
        },
      });
    },

    // Load data add page and size attributes
    async loadJsQuery(
      js_query: JsQuery,
      ppg: Pagination | "no_paginate" = defaultPagination
    ) {
      const lastQueryID = v4();
      js_query = { ...js_query };
      actions.setLoading(lastQueryID);

      if (ppg !== "no_paginate") {
        js_query.limit = ppg.perPage;
        js_query.offset = ppg.page * ppg.perPage;
      }

      const [
        { data },
        {
          data: [{ count }],
        },
      ] = await Promise.all([
        deps.axios.get<Array<T>>(`${uri}`, { params: { js_query } }),
        deps.axios.get<Array<{ count: number }>>(`${uri}`, {
          params: { js_query: countQuery(js_query) },
        }),
      ]);

      actions.setLoaded({
        data,
        lastQueryID,
        pagination:
          ppg != "no_paginate"
            ? { ...ppg, total: count }
            : { page: 1, perPage: count, total: count },
      });
    },

    async save(o: T) {
      if (o.id) {
        const { data: item } = await deps.axios.patch<T>(`${uri}/${o.id}`, o);
        if (eventKeys) events.emit(eventKeys.onUpdate, item as any);
        actions.updateItem(item);
        return item;
      } else {
        const { data: item } = await deps.axios.post<T>(`${uri}`, o);
        if (eventKeys) events.emit(eventKeys.onCreate, item as any);
        else await this.loadData();
        return item;
      }
    },

    async delete(o: T) {
      await api.delete(`${uri}/${o.id}`);
      if (eventKeys) events.emit(eventKeys.onDelete, o as any);
      else await this.loadData();
    },

    // Get element by id
    getItem(id: T["id"], params?: FullRestGetItemServiceParams): Promise<T> {
      return deps.axios
        .get<T>(`${uri}/${id}`, { params })
        .then(({ data }) => data);
    },

    // Get dropdown elements to load the elements into the dropdown data
    async getOptions() {
      const { data } = await deps.axios.get<DropdownOption<T>[]>(
        `${uri}/dropdown`
      );
      actions.setOptions(data);
    },
  };
}
