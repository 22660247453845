import React from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { FocusTrap } from "@mui/base";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Modal,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import defaults from "lodash/defaults";
import { useTranslation } from "next-i18next";
import { FormProvider, useForm } from "react-hook-form";

import { colors, sizes, theme } from "@castiero/web/common/theme";
import {
  Account,
  AccountSchema,
  AccountType,
} from "@castiero/web/schemas/Account";
import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { MuiCurrencyField } from "../fields/MuiCurrencyField";
import { MuiField } from "../fields/MuiField";
import { Col, Row } from "../grid";
import ModalHeader from "../MUI/modals/Header";
import { ModalBox } from "../styled/modal";
import { Splitter } from "../styled/splitter";

export const initAccount: FormValues<Account> = {
  id: "",
  type: "",
  reference: "",
  balance: "",
  bank: "",
  description: "",
  billing_day: "",
  payout_day: "",
  interest_factor: "",
  from_first_payment: false,
  company_id: "",
  display: "",
  percentage: "",
};

export type AccountFormType = FormValues<Account>;

export const AccountForm: React.FC<FormChildrenProp<Account>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  state,
  setState,
}) => {
  const localDefaultValues = defaults(defaultValues, initAccount);
  const methods = useForm<AccountFormType>({
    resolver: yupResolver(AccountSchema),
    defaultValues: localDefaultValues,
  });

  const [type, account_id] = methods.watch(["type", "id"]);
  const { formState } = methods;
  const { t } = useTranslation();

  return (
    <FocusTrap disableAutoFocus open>
      <Modal
        open={!!state}
        onClose={() => setState?.(false)}
        disableEnforceFocus
      >
        <ModalBox>
          <ModalHeader
            title={
              localDefaultValues.id
                ? t("edit_account")
                : t("create_new_account")
            }
            onClose={() => setState?.(false)}
          />
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <ToggleButtonGroup
                color="primary"
                value={type}
                exclusive
                onChange={(_, newType: AccountType) => {
                  methods.setValue("type" as const, newType);
                }}
                aria-label="type"
                fullWidth
                sx={{
                  [theme.breakpoints.down("md")]: {
                    display: type ? "flex" : "block",
                  },
                }}
              >
                {(
                  [
                    ["debit", "debit_samples"],
                    ["credit", "credit_samples"],
                    ["cost", "cost_center_samples"],
                  ] as const
                ).map(([name, description]) => (
                  <ToggleButton
                    key={name}
                    id={`${name}-account-button`}
                    selected={type == name}
                    value={name}
                    sx={{
                      ...formStyles.toggleButton,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      style={
                        type ? formStyles.typedTitle : formStyles.toggleTitle
                      }
                    >
                      {t(name)}
                    </Typography>
                    {!type ? (
                      <>
                        <Splitter style={formStyles.toggleSplitter} />
                        <Typography style={formStyles.sampleText}>
                          {t(description)}
                        </Typography>{" "}
                      </>
                    ) : null}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {type ? (
                <>
                  <Row className={`account-form-${type}`}>
                    <Col md={6}>
                      <MuiField
                        name="description"
                        label={t("description")}
                        id="account-description"
                        placeholder={t(`description_${type}`)}
                        error={formState.errors.description?.message}
                      />
                    </Col>
                    <Col md={6}>
                      <MuiField
                        label={t("reference")}
                        name="reference"
                        id="account-reference"
                        placeholder={t(`reference_${type}`)}
                        transform={(v) => v.toUpperCase().substring(0, 4)}
                        error={formState.errors.reference?.message}
                      />
                    </Col>
                    {type === "cost" && (
                      <Col md={6} sm={12}>
                        <MuiField
                          label={t("percentage")}
                          name="percentage"
                          id="account-percentage"
                          type="number"
                          placeholder={t(`percentage_${type}`)}
                          error={formState.errors.percentage?.message}
                        />
                      </Col>
                    )}

                    {type && type !== "cost" && (
                      <>
                        <Col md={6}>
                          <MuiCurrencyField
                            label={t("balance")}
                            name="balance"
                            id="account-balance"
                            placeholder={t(`balance_${type}`)}
                            error={formState.errors.balance?.message}
                          />
                        </Col>
                        <Col md={6}>
                          <MuiField
                            label={t("bank")}
                            id="account-bank"
                            name="bank"
                            placeholder={t(`bank_${type}`)}
                            error={formState.errors.bank?.message}
                          />
                        </Col>
                      </>
                    )}

                    {type === "credit" && (
                      <>
                        <Col md={6}>
                          <MuiField
                            label={t("billing_day")}
                            name="billing_day"
                            id="account-billing_day"
                            placeholder={t(`billing_day_${type}`)}
                            error={formState.errors.billing_day?.message}
                          />
                        </Col>
                        <Col md={6}>
                          <MuiField
                            label={t("payout_day")}
                            name="payout_day"
                            id="account-payout_day"
                            placeholder={t(`payout_day_${type}`)}
                            error={formState.errors.payout_day?.message}
                          />
                        </Col>
                        <Col md={6}>
                          <MuiField
                            label={t("interest_factor")}
                            name="interest_factor"
                            id="account-interest_factor"
                            placeholder={t(`interest_factor_${type}`)}
                            error={formState.errors.interest_factor?.message}
                          />
                        </Col>
                        <Col md={12}>
                          <FormGroup>
                            <FormControlLabel
                              id="from_first_payment"
                              control={<Checkbox />}
                              label={t`from_first_payment`}
                              name="from_first_payment"
                            />
                          </FormGroup>
                        </Col>
                      </>
                    )}
                  </Row>
                  <Col xs={12}>
                    {children(
                      () => methods.reset(localDefaultValues),
                      !account_id,
                      methods.formState
                    )}
                  </Col>
                </>
              ) : null}
            </form>
          </FormProvider>
        </ModalBox>
      </Modal>
    </FocusTrap>
  );
};

const formStyles: { [s: string]: React.CSSProperties } = {
  toggleButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    marginBlock: "20px",
  },
  toggleTitle: { fontWeight: "bold", fontSize: sizes.x5 },
  typedTitle: { fontWeight: "bold", fontSize: sizes.x3 },
  sampleText: {
    fontWeight: "bold",
    fontSize: sizes.x3,
    letterSpacing: 0.5,
    textTransform: "none",
  },
  toggleSplitter: {
    marginTop: sizes.x1,
    marginBottom: sizes.x1,
    borderColor: colors.gray,
  },
};
