import { BatchItemType, WritablePart } from "@castiero/web/common/types";
import { FieldValues, FormState } from "react-hook-form";
import * as yup from "yup";

export interface UserCompany {
  company_id: string;
  created_at: Date;
  role: "owner" | "admin" | "manager";
  cash_account_id?: number;
  updated_at: Date;
  user_id: number;
}

export type Cashflow = {
  incoming: number;
  expenses: number;
  incoming_batch: Array<BatchItemType>;
  expenses_batch: Array<BatchItemType>;
};

export type EmptyString = "";
export type FormValues<T, P = WritablePart<T>> = {
  [K in keyof P]: P[K] | EmptyString;
};

export type SelectOptions = Record<string, string>;

export type FormChildrenProp<F extends FieldValues> = {
  children: (
    onReset: () => void,
    create: boolean,
    state: FormState<FormValues<F>>
  ) => React.ReactNode;
  onSubmit: (o: FormValues<F>) => Promise<void> | void;
  defaults?: Partial<FormValues<F>>;
  state?: boolean;
  setState?: (s: boolean) => void;
  small?: boolean;
};

export const numberSchema = yup
  .number()
  .nullable()
  // checking self-equality works for NaN, transforming it to null
  .transform((_, val) => (/\d+/.test(String(val)) ? Number(val) : undefined));

type ConditionalSchema<T> = T extends string
  ? yup.StringSchema
  : T extends number
  ? yup.NumberSchema
  : T extends boolean
  ? yup.BooleanSchema
  : T extends Record<any, any>
  ? yup.AnyObjectSchema
  : T extends Array<any>
  ? yup.ArraySchema<any, any>
  : T extends Date
  ? yup.DateSchema
  : yup.AnySchema;

export type Shape<Fields> = {
  [Key in keyof Fields]: ConditionalSchema<Fields[Key]>;
};
