import { useApiService } from "@castiero/web/api/apiService";
import { colors, sizes, theme } from "@castiero/web/common/theme";
import { toastSaveParams } from "@castiero/web/common/toast";
import { ButtonPrimary } from "@castiero/web/components/buttons";
import {
  initTag,
  TagForm,
  TagFormType,
} from "@castiero/web/components/forms/TagForm";
import { FormValues } from "@castiero/web/schemas/common";
import { Tag, TagCast } from "@castiero/web/schemas/Tag";
import { Grid, Modal } from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import { defaults } from "lodash";
import { useTranslation } from "next-i18next";
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { toast } from "react-toastify";
import ModalHeader from "../../components/MUI/modals/Header";
import { ModalBox } from "../../components/styled/modal";
import { FormContext } from "../common/types";

const TagFormContext = createContext<FormContext<Tag, TagFormType>>(
  undefined as never
);

export const useTagFormSubmit = () => {
  const apiService = useApiService();
  const { t } = useTranslation();
  return async (data: FormValues<TagFormType>) => {
    await toast.promise(
      apiService.tags.save(TagCast(data)),
      toastSaveParams("tags", t)
    );
  };
};

export const TagFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [active, setActive] = useState(false);
  const [values, setValues] = useState<TagFormType>();
  const toggle = useCallback(
    (_active: boolean, tag?: Partial<TagFormType>) => {
      console.log(tag, defaults({}, tag, initTag()), defaults(initTag(), tag));
      setValues(defaults(tag, initTag()));
      setActive(_active != active);
    },
    [active]
  );

  const { t } = useTranslation();

  const state = useMemo(
    () => ({ active, values, toggle }),
    [active, values, toggle]
  );

  const onSubmit = useTagFormSubmit();

  return (
    <TagFormContext.Provider value={state}>
      {children}
      {active ? (
        <FocusTrap disableAutoFocus open>
          <Modal
            open={!!active}
            onClose={() => setActive(false)}
            disableEnforceFocus
          >
            <ModalBox>
              <ModalHeader
                title={state.values?.id ? t("edit_tag") : t("create_new_tag")}
                onClose={() => setActive(false)}
              />
              <TagForm
                defaults={values}
                state={true}
                setState={setActive}
                onSubmit={(t) =>
                  onSubmit(t).then(() => {
                    setActive(false);
                    setValues(undefined);
                  })
                }
              >
                {(onReset, create) => (
                  <>
                    <Grid item xs={12} sm={3}>
                      <Grid
                        container
                        spacing={sizes.x3}
                        height={`calc(100% + ${sizes.x3})`}
                      >
                        <Grid item xs={6}>
                          <ButtonPrimary
                            type="button"
                            onClick={() => {
                              onReset();
                              setActive(false);
                            }}
                            style={{
                              backgroundColor: theme.palette.error.main,
                              height: "100%",
                            }}
                          >
                            {t("cancel")}
                          </ButtonPrimary>
                        </Grid>
                        <Grid item xs={6}>
                          <ButtonPrimary
                            type="submit"
                            style={{
                              backgroundColor: colors.success,
                              height: "100%",
                            }}
                          >
                            {!create ? t("save") : t("create")}
                          </ButtonPrimary>
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
              </TagForm>
            </ModalBox>
          </Modal>
        </FocusTrap>
      ) : null}
    </TagFormContext.Provider>
  );
};

export const useTagForm = () => {
  const context = useContext(TagFormContext);
  if (!context) {
    throw new Error("useTagForm must be used within a TagFormProvider");
  }
  return context;
};
