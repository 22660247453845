import { AxiosResponse } from "axios";
import { JsQuery } from "../../types/JsQuery";
import { ApiServiceDeps } from "../types";

export type JSQueryService = <T>(query: JsQuery) => Promise<AxiosResponse<T[]>>;

export default function jsQueryServiceFactory(
  deps: ApiServiceDeps
): (model: string) => JSQueryService {
  const { axios } = deps;
  return (model) => (js_query) => axios.get(model, { params: { js_query } });
}
