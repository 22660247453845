import { Flatten } from "@castiero/modules/types/Flatten";
import { IIdentify } from "@castiero/web/store/types";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { visuallyHidden } from "@mui/utils";
import * as React from "react";
import { sizes } from "../../../common/theme";
import { Order } from "./EnhancedTable";

export type HeadCell<F extends IIdentify> = (
  | {
      calculated?: false;
      id: keyof Flatten<F> | keyof F;
      formatter?: (v: any) => string | JSX.Element;
    }
  | {
      calculated: true;
      id: string;
      formatter: (v: F) => string | JSX.Element | null;
    }
) & {
  label: string;
  disablePadding?: boolean;
  numeric?: boolean;
  noSortBy?: boolean;
};

interface EnhancedHeaderTableProps<F extends IIdentify> {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Flatten<F> | keyof F
  ) => void;
  onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order?: Order<F>;
  rowCount: number;
  headcells: HeadCell<F>[];
  tableHeadColor?: string;
  titleColor?: string;
  selectColor?: string;
  actions?: boolean;
  checkbox?: boolean;
}

export const EnhancedTableHead = <F extends IIdentify>(
  props: EnhancedHeaderTableProps<F>
) => {
  const {
    onSelectAllClick,
    order = { by: "", dir: "asc" },
    numSelected,
    rowCount,
    onRequestSort,
    headcells,
    tableHeadColor,
    titleColor,
    selectColor,
    checkbox,
    actions,
  } = props;
  const createSortHandler =
    (header: HeadCell<F>) => (event: React.MouseEvent<unknown>) => {
      if (!header.calculated && !header.noSortBy)
        onRequestSort(event, header.id);
    };

  return (
    <TableHead
      sx={{
        background: tableHeadColor,
        boxShadow: "1px 1px 3px -1px #7f7f7fee",
      }}
    >
      <TableRow>
        {checkbox ? (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts",
              }}
              sx={{ color: titleColor }}
            />
          </TableCell>
        ) : (
          ""
        )}

        {headcells?.map((headCell) => (
          <TableCell
            key={String(headCell.id)}
            align={headCell.numeric ? "right" : "left"}
            sortDirection={order.by === headCell.id ? order.dir : false}
            sx={{ opacity: 1, borderBottom: "unset" }}
          >
            <TableSortLabel
              sx={{
                color: titleColor,
                fontWeight: "bolder",
                transition: "0.3s",
                "&.Mui-active": {
                  color: selectColor,
                  textShadow: "1px 1px 5px #EAE1D911",
                },
                "&:hover": {
                  color: headCell.noSortBy ? titleColor : selectColor,
                  cursor: headCell.noSortBy ? "auto" : "pointer",
                },
                textTransform: "uppercase",
                textShadow: "1px 1px 5px #635D5711",
                fontSize: { xs: sizes.x3, sm: sizes.x4 },
              }}
              active={order.by === headCell.id}
              direction={order.by === headCell.id ? order.dir : "asc"}
              onClick={createSortHandler(headCell)}
              hideSortIcon={headCell.noSortBy}
            >
              {headCell.label}
              {order.by === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order.dir === "desc"
                    ? "sorted descending"
                    : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {actions ? (
          <TableCell sx={{ opacity: 1, borderBottom: "unset" }}></TableCell>
        ) : null}
      </TableRow>
    </TableHead>
  );
};
