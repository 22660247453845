import { chartColors } from "@castiero/web/common/chartColors";
import { FormChildrenProp, FormValues } from "@castiero/web/schemas/common";
import { Tag, TagSchema } from "@castiero/web/schemas/Tag";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { useTranslation } from "next-i18next";
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { sizes } from "../../common/theme";
import { MuiField } from "../fields/MuiField";

export type TagFormType = FormValues<Tag>;

export const initTag: () => TagFormType = () => ({
  id: "",
  name: "",
  color: chartColors[Math.floor(Math.random() * chartColors.length)],
  company_id: "",
});

export const TagForm: React.FC<FormChildrenProp<Tag>> = ({
  onSubmit,
  children,
  defaults: defaultValues,
  small,
}) => {
  const methods = useForm<FormValues<Tag & { type: string }>>({
    resolver: yupResolver(TagSchema),
    defaultValues: defaultValues ?? initTag(),
  });

  const [id] = methods.watch(["id", "type"] as const);
  const { formState, reset } = methods;
  const { t } = useTranslation();
  useEffect(() => {
    if (defaultValues) reset(defaultValues);
  }, [defaultValues]);

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={methods.handleSubmit(onSubmit)}>
        <Grid
          container
          spacing={sizes.x3}
          direction={{ xs: "column", sm: "row" }}
        >
          <Grid item sm={9} xs={12}>
            <Grid container spacing={sizes.x3}>
              <Grid item sm={6} xs={12}>
                <MuiField
                  label={t`name`}
                  noPadding
                  size={small ? "small" : "medium"}
                  name="name"
                  error={formState.errors.name?.message}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <MuiField
                  label={t`color`}
                  id="colors"
                  type="color"
                  name="color"
                  noPadding
                  size={small ? "small" : "medium"}
                  shrink
                  inputProps={{ list: "colorListDefined" }}
                />
                <datalist id="colorListDefined">
                  {chartColors.map((color) => (
                    <option
                      key={color}
                      value={color}
                      style={{ borderRadius: "50%" }}
                    />
                  ))}
                </datalist>
              </Grid>
            </Grid>
          </Grid>
          {children(() => reset(initTag()), !id, methods.formState)}
        </Grid>
      </Box>
    </FormProvider>
  );
};
