import { TFunction } from "next-i18next";
import { ToastPromiseParams } from "react-toastify";

export const toastSaveParams = (
  source: string,
  t: TFunction
): ToastPromiseParams<any> => ({
  pending: t(`${source}.saving`),
  success: {
    render() {
      return String(t(`${source}.saved`));
    },
    autoClose: 1500,
  },
  error: t(`${source}.saving_error`),
});
export const toastDeleteParams = (
  source: string,
  t: TFunction
): ToastPromiseParams => ({
  pending: t(`${source}.deleting`),
  success: {
    render() {
      return String(t(`${source}.deleted`));
    },
    autoClose: 1000,
  },
  error: t(`${source}.deleting_error`),
});
