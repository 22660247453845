import { TextField, TextFieldProps } from "@mui/material";
import React from "react";
import { Control, Controller } from "react-hook-form";

export const MuiField = React.forwardRef<
  HTMLInputElement,
  {
    label: string;
    shrink?: boolean;
    control?: Control<any>;
    error?: string;
    noPadding?: boolean;
    transform?: (s: string) => string;
  } & Omit<TextFieldProps, "error" | "InputLabelProps">
>(({ label, control, error, noPadding, transform, shrink, ...props }, ref) => {
  return (
    <Controller
      control={control}
      name={props.name || ""}
      render={({ field: { onChange, value } }) => {
        return (
          <TextField
            id="outlined-basic"
            ref={ref}
            label={label}
            variant="outlined"
            onChange={(val) => {
              onChange(transform?.(val.target.value) || val);
            }}
            helperText={error}
            value={value}
            sx={{
              width: "100%",
              marginBottom: noPadding ? 0 : "20px",
            }}
            error={!!error}
            InputLabelProps={{ shrink }}
            {...props}
          />
        );
      }}
    />
  );
});

MuiField.displayName = "MuiField";
