import { Flatten } from "@castiero/modules/types/Flatten";
import { IIdentify } from "@castiero/web/store/types";
import { colors } from "@mui/material";
import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { get } from "lodash";
import * as React from "react";
import { MuiTablePagination } from "./MuiTablePagination";
import { EnhancedTableHead, HeadCell } from "./TableHead";
import { EnhancedTableToolbar } from "./TableToolBar";

export type Order<T extends IIdentify> = {
  by: keyof Flatten<T> | keyof T;
  dir: "asc" | "desc";
};

export type Pagination = {
  page: number;
  perPage: number;
  total: number;
};

export const defaultPagination: Pagination = {
  page: 0,
  perPage: 15,
  total: 0,
};

interface TableProps<T extends IIdentify> {
  data?: T[];
  headers: HeadCell<T>[];
  order?: Order<T>;
  tableTitle?: string;
  pagination?: Pagination;
  compact?: boolean;
  selection?: T["id"][];
  secondary?: boolean;
  Actions?: React.FC<{ data: T }>;
  checkbox?: boolean;
  id?: string;

  // Handlers
  onDelete?: () => Promise<void>;
  onOrderChange?: (o: Order<T>) => void;
  onPaginationChange?: (o: Pagination) => void;
  onSelectionChange?: (o: T["id"][]) => void;
}

export const EnhancedTable = <T extends IIdentify>({
  headers,
  data,
  order,
  tableTitle,
  pagination,
  compact,
  selection = [],
  secondary,
  checkbox,
  Actions,
  id,
  onDelete,
  onOrderChange,
  onSelectionChange,
  onPaginationChange,
}: TableProps<T>) => {
  const selectColor = secondary ? "#FDEFDD" : "#FBBB4A",
    tableHeadColor = secondary ? "#FBBB4A" : "#809871",
    titleColor = secondary ? "#809871" : "#FDEFDD";
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Flatten<T> | keyof T
  ) => {
    const isAsc = order?.by === property && order.dir === "asc";
    onOrderChange?.({ by: property, dir: isAsc ? "desc" : "asc" });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = data?.map((n) => n.id) as string[];
      onSelectionChange?.(newSelected);

      return;
    }
    onSelectionChange?.([]);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    name: string | number
  ) => {
    const selectedIndex = selection.indexOf(name);
    let newSelected: (string | number)[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selection, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selection.slice(1));
    } else if (selectedIndex === selection.length - 1) {
      newSelected = newSelected.concat(selection.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selection.slice(0, selectedIndex),
        selection.slice(selectedIndex + 1)
      );
    }

    onSelectionChange?.(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    if (pagination) {
      onPaginationChange?.({
        ...pagination,
        page: newPage - 1,
      });
    }
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (pagination) {
      onPaginationChange?.({
        ...pagination,
        page: 0,
        perPage: parseInt(event.target.value),
      });
    }
  };

  const isSelected = (name: string | number) => selection.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows = ({ perPage: pp }: Pagination) => {
    return pp - (data?.length ?? 0);
  };

  return (
    <Box id={id} sx={{ width: "100%" }}>
      {tableTitle ? (
        <EnhancedTableToolbar
          onDelete={onDelete}
          tableTitle={tableTitle}
          numSelected={selection.length}
        />
      ) : null}
      <TableContainer>
        <Table
          sx={{
            minWidth: 480,
            border: "none",
            borderCollapse: "separate",
            borderSpacing: "0 4px",
          }}
          aria-labelledby="tableTitle"
          size={compact ? "small" : "medium"}
        >
          <EnhancedTableHead
            numSelected={selection.length}
            order={order}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={data?.length ?? 0}
            headcells={headers}
            tableHeadColor={tableHeadColor}
            titleColor={titleColor}
            selectColor={selectColor}
            checkbox={checkbox}
            actions={!!Actions}
          />
          <TableBody>
            {data?.map((row, index) => {
              const isItemSelected = isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  onClick={(event: React.MouseEvent) =>
                    checkbox ? handleClick(event, row.id) : ""
                  }
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row.id}
                  selected={isItemSelected}
                >
                  {checkbox ? (
                    <TableCell
                      padding="checkbox"
                      sx={{
                        borderColor: colors.green[100],
                        borderWidth: 2,
                      }}
                    >
                      <Checkbox
                        color="primary"
                        checked={isItemSelected}
                        inputProps={{
                          "aria-labelledby": labelId,
                        }}
                      />
                    </TableCell>
                  ) : (
                    ""
                  )}

                  {headers?.map(
                    (
                      { id, formatter, numeric, calculated, disablePadding },
                      index
                    ) => {
                      return (
                        <TableCell
                          key={`${index}-${String(id)}`}
                          sx={{
                            padding: disablePadding ? "" : "4px 8px",
                            maxWidth: "180px",
                            borderColor: colors.green[900],
                          }}
                          size="small"
                          align={numeric ? "right" : "left"}
                        >
                          {calculated ? (
                            formatter(row)
                          ) : (
                            <span
                              style={{
                                display: "inline-block",
                                maxWidth: "100%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {formatter?.(get(row, id)) ??
                                String(get(row, id))}
                            </span>
                          )}
                        </TableCell>
                      );
                    }
                  )}
                  {Actions ? (
                    <TableCell
                      align="right"
                      size={compact ? "small" : "medium"}
                      padding="none"
                      sx={{
                        whiteSpace: "nowrap",
                        borderColor: colors.green[900],
                      }}
                    >
                      <Actions data={row} />
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
            {pagination && emptyRows(pagination) > 0 && (
              <TableRow
                style={{
                  height: (compact ? 33 : 53) * emptyRows(pagination),
                }}
              >
                <TableCell colSpan={1 + headers.length + (Actions ? 1 : 0)} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && (
        <MuiTablePagination
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          page={pagination.page}
          perPage={pagination.perPage}
          total={pagination.total}
        />
      )}
    </Box>
  );
};
